var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                    "is-search-input-title":
                      "门店编号、门店名称、商品编号、商品名称、条码、销售小票号",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "marT10 marB10 x-f" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("商品"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listGoods",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择商品",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({
                                          key: "goods",
                                        }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.goodsIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "goodsIds", $$v)
                            },
                            expression: "queryParams.goodsIds",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("商品分类"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listCategory",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择商品类别",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({
                                          key: "category",
                                        }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.categoryIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "categoryIds", $$v)
                            },
                            expression: "queryParams.categoryIds",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("收银机"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listMachine",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择收银机",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({
                                          key: "machine",
                                        }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.machineIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "machineIds", $$v)
                            },
                            expression: "queryParams.machineIds",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("收银员"),
                        ]),
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listCashier",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择收银员",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({
                                          key: "cashier",
                                        }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.cashierIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "cashierIds", $$v)
                            },
                            expression: "queryParams.cashierIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("门店"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listShop",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择门店",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({ key: "shop" }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.shopIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "shopIds", $$v)
                            },
                            expression: "queryParams.shopIds",
                          },
                        }),
                        _vm.activeName == "shop"
                          ? _c("span", { staticClass: "fS14MR10" }, [
                              _vm._v("业务类型"),
                            ])
                          : _vm._e(),
                        _vm.activeName == "shop"
                          ? _c("SelectLocal", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                multiple: true,
                                option: {
                                  data: _vm.lineTypeData,
                                  value: "value",
                                  label: "label",
                                },
                              },
                              model: {
                                value: _vm.queryParams.lineTypes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "lineTypes", $$v)
                                },
                                expression: "queryParams.lineTypes",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "门店销售分析" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "门店销售分析明细", name: "shop" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable1",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                prop: "userId",
                                type: "index",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售日期",
                                align: "center",
                                prop: "billDateStr",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机编码",
                                align: "center",
                                prop: "cashierNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机名称",
                                align: "center",
                                prop: "cashierName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员编码",
                                align: "center",
                                prop: "cashierNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员",
                                align: "center",
                                prop: "cashierName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "小票号",
                                align: "center",
                                prop: "billNo",
                                width: "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编码",
                                align: "center",
                                prop: "categoryNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品类别",
                                align: "center",
                                prop: "categoryName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "原价",
                                sortable: "",
                                align: "center",
                                prop: "unitOldPrice",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "售价",
                                sortable: "",
                                align: "center",
                                prop: "unitPrice",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "价格类型",
                                align: "center",
                                prop: "agioTypeName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "折扣金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoney1",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "折上折类型",
                                align: "center",
                                prop: "agioType2Name",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "折上折比率%",
                                sortable: "",
                                align: "center",
                                prop: "agioRate2",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "折上折金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoney2",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "总优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoneySum",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "舍入金额",
                                sortable: "",
                                align: "center",
                                prop: "roundMoney",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "业务类型",
                                align: "center",
                                prop: "lineTypeName",
                                width: "110",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "商品销售分析", name: "goods" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品类别",
                                align: "center",
                                prop: "categoryName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "售卖单位",
                                align: "center",
                                prop: "unitName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "总优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoneySum",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单品客单数",
                                sortable: "",
                                align: "center",
                                prop: "customerOrdersQty",
                                width: "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "门店销售分析(分店+日期)",
                          name: "shopDate",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售日期",
                                align: "center",
                                prop: "billDateStr",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoneySum",
                                width: "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "门店销售分析(收银员)",
                          name: "cashier",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售日期",
                                align: "center",
                                prop: "billDateStr",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员",
                                align: "center",
                                prop: "cashierName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoneySum",
                                width: "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "日期汇总查询", name: "date" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                prop: "userId",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售日期",
                                align: "center",
                                prop: "billDateStr",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "类别汇总查询", name: "category" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编号",
                                align: "center",
                                prop: "categoryNo",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品类别",
                                align: "center",
                                prop: "categoryName",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "赠送数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQtyGift",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "赠送金额",
                                sortable: "",
                                align: "center",
                                prop: "unitMoneyGift",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoneySum",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "退货数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQtyReturn",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "退货金额",
                                sortable: "",
                                align: "center",
                                prop: "unitMoneyReturn",
                                width: "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "时间段汇总查询", name: "time" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                prop: "userId",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "时间段",
                                align: "center",
                                prop: "timeSlot",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "总优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoneySum",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    "is-select-all": false,
                    "total-com": _vm.total,
                  },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }