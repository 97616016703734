var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                    "is-search-input-title": "门店编号、门店名称",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "marT10 x-f" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("收款类型"),
                        ]),
                        _c("SelectLocal", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: {
                              data: _vm.payTypeList,
                              value: "value",
                              label: "label",
                            },
                          },
                          model: {
                            value: _vm.queryParams.payTypeIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "payTypeIds", $$v)
                            },
                            expression: "queryParams.payTypeIds",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("门店"),
                        ]),
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listShop",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择门店",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({ key: "shop" }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.shopIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "shopIds", $$v)
                            },
                            expression: "queryParams.shopIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "门店收银日报" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingTable,
                        expression: "loadingTable",
                      },
                    ],
                    key: Math.random(),
                    ref: "multipleTable",
                    attrs: {
                      "tooltip-effect": "dark",
                      data: _vm.tableData,
                      border: "",
                      "show-summary": "",
                      "max-height": _vm.tableHeight,
                      height: _vm.tableHeight,
                      "summary-method": _vm.getSummaries,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        align: "center",
                        prop: "userId",
                        width: "80",
                        type: "index",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "日期",
                        align: "center",
                        width: "120",
                        prop: "billDateStr",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "门店编号",
                        align: "center",
                        prop: "shopNo",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "门店名称",
                        align: "center",
                        prop: "shopName",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "收款类型",
                        align: "center",
                        prop: "payType",
                      },
                    }),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "应收金额",
                          align: "center",
                          minWidth: "120",
                          prop: "receivableMoney",
                        },
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c(
                            "div",
                            { staticClass: "header-container" },
                            [
                              _vm._v(" 应收金额 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "tooltip",
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "应收款金额，不包含折扣优惠、抹零、积分抵现、优惠券等优惠扣减的金额，但包含会员卡储值消费的金额",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { "font-size": "14px" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "实收金额",
                          align: "center",
                          minWidth: "120",
                          prop: "billFactMoney",
                        },
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c(
                            "div",
                            { staticClass: "header-container" },
                            [
                              _vm._v(" 实收金额 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "tooltip",
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "实收金额，不包含折扣优惠、抹零、积分抵现、优惠券等优惠扣减的金额，且不包含会员卡储值消费的金额",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { "font-size": "14px" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                    _c("el-table-column", {
                      attrs: {
                        label: "营收金额",
                        sortable: "",
                        minWidth: "120",
                        align: "center",
                        prop: "revenueMoney",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "客单数",
                        sortable: "",
                        minWidth: "120",
                        align: "center",
                        prop: "customerUnitQty",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "客单价",
                        sortable: "",
                        align: "center",
                        prop: "customerUnitPrice",
                        minWidth: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "退货笔数",
                        sortable: "",
                        minWidth: "120",
                        align: "center",
                        prop: "refundCount",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "退货金额",
                        sortable: "",
                        align: "center",
                        prop: "refundMoney",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "优惠总额",
                        sortable: "",
                        align: "center",
                        prop: "agioMoneySum",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "赠送金额",
                        sortable: "",
                        align: "center",
                        prop: "largessMoney",
                        width: "100",
                      },
                    }),
                    _c(
                      "el-table-column",
                      { attrs: { label: "收款方式", align: "center" } },
                      _vm._l(_vm.payModeList, function (item, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.value,
                            prop: item.value + "." + item.payMode,
                            align: "center",
                            sortable: "",
                            width: "120",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.payDetailList
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.payMoneyCompute(
                                                  item.payMode,
                                                  scope.row.payDetailList
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _c("span", [_vm._v("0")]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    "is-select-all": false,
                    "total-com": _vm.total,
                  },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }