<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="门店编号、门店名称"
            @isShowHighCom="getIsShowHigh"
          />
          <div v-show="showHigh">
            <div class="marT10 x-f">
              <span
                class="fS14MR10"
                v-show="
                  activeName == 'collection' ||
                  activeName == 'collectionNo' ||
                  activeName == 'pay'
                "
                >收款方式</span
              >
              <SelectRemote
                v-show="
                  activeName == 'collection' ||
                  activeName == 'collectionNo' ||
                  activeName == 'pay'
                "
                class="marR15"
                :multiple="true"
                v-model="queryParams.payModeIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listPayClass',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择收款方式',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'paymode' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span
                class="fS14MR10"
                v-show="
                  activeName != 'cashier' &&
                  activeName != 'collectionNo' &&
                  activeName != 'billDate'
                "
                >收银员</span
              >
              <SelectRemote
                v-show="
                  activeName != 'cashier' &&
                  activeName != 'collectionNo' &&
                  activeName != 'billDate'
                "
                class="marR15"
                :multiple="true"
                v-model="queryParams.cashierIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listCashier',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择收银员',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'cashier' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span
                class="fS14MR10"
                v-show="
                  activeName != 'collectionNo' &&
                  activeName != 'pay' &&
                  activeName != 'billDate'
                "
                >门店</span
              >
              <SelectRemote
                v-show="
                  activeName != 'collectionNo' &&
                  activeName != 'pay' &&
                  activeName != 'billDate'
                "
                :multiple="true"
                class="marR15"
                v-model="queryParams.shopIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择门店',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'shop' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10">是否营收</span>
              <el-select
                size="mini"
                v-model="queryParams.isRevenue"
                placeholder="请选择"
                clearable
                filterable
              >
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom card-title="门店收款汇总查询" class="orderInfo">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="门店收款明细分析" name="payDetail">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="160"
                />

                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />
                <el-table-column
                  label="销售时间"
                  align="center"
                  prop="billDate"
                  width="160"
                />
                <el-table-column
                  label="销售小票号"
                  align="center"
                  prop="billNo"
                  width="200"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row.billNo)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="收银员名称"
                  align="center"
                  prop="cashierName"
                  width="160"
                />
                <el-table-column
                  label="收银员编号"
                  align="center"
                  prop="cashierNo"
                  width="120"
                />
                <el-table-column
                  label="线上充值"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="自营商城"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="收款笔数"
                  align="center"
                  prop="payCount"
                  sortable
                  minWidth="120"
                />
                <el-table-column
                  label="收款金额"
                  sortable
                  align="center"
                  prop="billFactMoney"
                  width="120"
                />
                <el-table-column
                  label="收款方式"
                  align="center"
                  prop="payModeId"
                >
                  <div v-for="(item, index) in payModeList" :key="index">
                    <el-table-column
                      :label="item.payModeName"
                      :key="item.payModeId"
                      align="center"
                      sortable
                      min-width="100"
                      :prop="`payDetailList.${item.payModeId}.payMoney`"
                    >
                      <template slot-scope="scope">
                        <span
                          v-if="scope.row.payDetailList"
                          :style="{
                            fontWeight:
                              item.payModeName == '汇总' ? 'bold' : 'normal'
                          }"
                        >
                          {{
                            payMoneyCompute(
                              item.payModeId,
                              scope.row.payDetailList
                            )
                          }}
                        </span>
                        <span
                          v-else
                          :style="{
                            fontWeight:
                              item.payModeName == '汇总' ? 'bold' : 'normal'
                          }"
                          >0</span
                        >
                      </template>
                    </el-table-column>
                  </div>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="收款汇总查询(门店+收款方式+收银员)"
              name="collection"
            >
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="日期"
                  align="center"
                  prop="billDateStr"
                  width="150"
                >
                </el-table-column>
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                />
                <el-table-column
                  label="收银员编号"
                  align="center"
                  prop="cashierNo"
                />
                <el-table-column
                  label="收银员"
                  align="center"
                  prop="cashierName"
                />
                <el-table-column
                  label="线上充值"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="自营商城"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="收款方式"
                  align="center"
                  prop="payModeName"
                />
                <el-table-column
                  label="收款笔数"
                  align="center"
                  prop="payCount"
                  sortable
                />
                <el-table-column
                  label="收款金额"
                  sortable
                  align="center"
                  prop="payMoney"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="收款汇总查询(按门店)" name="cashier">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="日期"
                  align="center"
                  prop="billDateStr"
                  width="150"
                >
                </el-table-column>
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                />
                <el-table-column
                  label="线上充值"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="自营商城"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="收款笔数"
                  align="center"
                  prop="payCount"
                  sortable
                />
                <el-table-column
                  label="收款金额"
                  sortable
                  align="center"
                  prop="payMoney"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="收款汇总查询(按收款方式)" name="collectionNo">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="收款方式"
                  align="center"
                  prop="payModeName"
                />
                <el-table-column
                  label="线上充值"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="自营商城"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="收款笔数"
                  align="center"
                  prop="payCount"
                  sortable
                />
                <el-table-column
                  label="收款金额"
                  align="center"
                  prop="payMoney"
                  sortable
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="收款汇总查询(按收款方式+收银员)" name="pay">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  show-overflow-tooltip
                  label="日期"
                  align="center"
                  prop="billDateStr"
                />

                <el-table-column
                  label="收款方式"
                  align="center"
                  prop="payModeName"
                />
                <el-table-column
                  label="收银员"
                  align="center"
                  prop="cashierName"
                />
                <el-table-column
                  label="收银员编码"
                  align="center"
                  prop="cashierNo"
                />
                <el-table-column
                  label="线上充值"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="自营商城"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="收款笔数"
                  align="center"
                  prop="payCount"
                  sortable
                />
                <el-table-column
                  label="收款金额"
                  sortable
                  align="center"
                  prop="payMoney"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="收款汇总查询(按日期)" name="billDate">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column label="日期" align="center" prop="billDate" />
                <el-table-column
                  label="线上充值"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="自营商城"
                  align="center"
                  prop="billFactMoney"
                  minWidth="120"
                />
                <el-table-column
                  label="收款笔数"
                  align="center"
                  prop="payCount"
                  sortable
                />
                <el-table-column
                  label="收款金额"
                  align="center"
                  prop="payMoney"
                  sortable
                />
              </el-table>
            </el-tab-pane>
          </el-tabs>

          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :is-select-all="false"
            :total-com="total"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  listPayCashierAPI,
  listPayCashierSummaryAPI,
  listPayCountAPI,
  listPayCountSummaryAPI,
  listPayTotalAPI,
  listPayTotalSummaryAPI,
  listShopPayCashierAPI,
  listShopPayCashierSummaryAPI
} from '@/api/shop/analyse/posGather'
import { billListAPI, billListSummaryAPI } from '@/api/shop/report/saleTotal' // 门店销售列表
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import { fcount, isObject, isNumber } from '@/utils'
export default {
  name: 'PosGather',
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectRemote
  },
  data () {
    return {
      activeName: 'payDetail',
      summary: [],
      summaryDateils: {},
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'shopNos', name: '门店编号' },
        { parameter: 'shopNames', name: '门店名称' },
        { parameter: 'billNos', name: '小票号' }
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      exportType: {
        payDetail: '/api/system/shop/report/sale/summary/listBillNoExport',
        collection:
          '/api/system/shop/report/pay/analysis/listShopPayCashierExport',
        cashier: '/api/system/shop/report/pay/analysis/listShopExport',
        collectionNo: '/api/system/shop/report/pay/analysis/listPayCountExport',
        pay: '/api/system/shop/report/pay/analysis/listPayCashierExport'
      }, //定义导出枚举
      exportNameType: {
        payDetail: '门店收款分析（门店收款明细分析）',
        collection: '门店收款分析（收款汇总查询（门店+收款方式+收银员））',
        cashier: '门店收款分析（收款汇总查询（门店））',
        collectionNo: '门店收款分析（收款笔数查询）',
        pay: '门店收款分析（收款汇总查询（收款方式+收银员））'
      }, //定义导出名称枚举
      payModeList: [],
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 130
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        this.exportType[this.activeName],
        {
          ...this.queryParams
        },
        `${this.exportNameType[this.activeName]}导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //计算属性
    payMoneyCompute (payModeId, arr) {
      if (arr) {
        const findIndex = arr.findIndex(item => item.payModeId == payModeId)
        if (findIndex != -1) {
          return arr[findIndex].payMoney
        } else {
          return 0
        }
      }
    },
    // 分页
    handleClick (tab) {
      this.getList()
    },
    /** 查询表格列表 */
    async getList () {
      try {
        this.loadingTable = true
        let res = {}
        let res1 = {}
        if (this.activeName == 'collection') {
          res = await listShopPayCashierAPI(this.queryParams)
          res1 = await listShopPayCashierSummaryAPI(this.queryParams)
        } else if (this.activeName == 'cashier') {
          res = await listPayTotalAPI(this.queryParams)
          res1 = await listPayTotalSummaryAPI(this.queryParams)
        } else if (this.activeName == 'collectionNo') {
          res = await listPayCountAPI(this.queryParams)
          res1 = await listPayCountSummaryAPI(this.queryParams)
        } else if (this.activeName == 'pay') {
          res = await listPayCashierAPI(this.queryParams)
          res1 = await listPayCashierSummaryAPI(this.queryParams)
        } else {
          // 存在payTypes，调用billListAPI
          res = await billListAPI(this.queryParams)
          res1 = await billListSummaryAPI(this.queryParams)
          this.payModeList = res1?.data?.payDetailList || []
          this.payModeList.sort((a, b) => {
            return a.payModeId - b.payModeId // Sort the rest based on payModeId
          })
        }
        this.tableData = res.rows
        this.total = res.total
        this.summaryDateils = res1.data || {}
      } finally {
        this.loadingTable = false
      }
    },

    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    // 跳转详情
    handleDetail (billNo) {
      this.$router.push({
        name: 'saleTotalDetail',
        query: {
          billNo: billNo,
          type: 'Update'
        }
      })
    },
    getSummaries ({ columns, data } = {}) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const sumItem = this.summary?.find(x =>
          isObject(x) ? x.key === column.property : x === column.property
        )
        if (sumItem || this.summaryDateils != '{}') {
          if (this.summaryDateils) {
            const arr = column?.property?.split('.')
            if (arr.length == 1) {
              sums[index] = this.summaryDateils[column.property] || ''
            } else if (arr.length == 3) {
              let sumsObj = this.summaryDateils?.payDetailList?.find(
                v => v.payModeId == arr[1]
              )
              sums[index] = sumsObj ? sumsObj[arr[2]] : ''
            }
          } else {
            const arr = column.property.split('.')
            let values = data.map(x => Number(x[column.property] || 0))
            if (arr?.length === 4) {
              const [p, i, key, s] = arr
              values = data.map(
                x => x[p]?.find?.(x => String(x[key]) === i)?.[s] || 0
              )
            }
            sums[index] = `${values.reduce(
              (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], '+'),
              0
            )}${isObject(sumItem) ? ` ${sumItem.unit}` : ''}`
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>
<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .orderInfo {
    height: calc(100% - 94px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
</style>
