<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="门店编号、门店名称"
            @isShowHighCom="getIsShowHigh"
          />
          <div v-show="showHigh">
            <div class="marT10 x-f">
              <span class="fS14MR10">收款类型</span>
              <SelectLocal
                class="marR15"
                :multiple="true"
                v-model="queryParams.payTypeIds"
                style="width: 250px"
                :option="{
                  data: payTypeList,
                  value: 'value',
                  label: 'label'
                }"
              />
              <span class="fS14MR10">门店</span>
              <SelectRemote
                :multiple="true"
                v-model="queryParams.shopIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择门店',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'shop' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom card-title="门店收银日报" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-table
            ref="multipleTable"
            v-loading="loadingTable"
            tooltip-effect="dark"
            :data="tableData"
            border
            show-summary
            :max-height="tableHeight"
            :height="tableHeight"
            :summary-method="getSummaries"
            :key="Math.random()"
          >
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              label="日期"
              align="center"
              width="120"
              prop="billDateStr"
            />
            <el-table-column
              show-overflow-tooltip
              label="门店编号"
              align="center"
              prop="shopNo"
              min-width="120"
            />
            <el-table-column
              label="门店名称"
              align="center"
              prop="shopName"
              min-width="120"
            />
            <el-table-column label="收款类型" align="center" prop="payType" />
            <el-table-column
              label="应收金额"
              align="center"
              minWidth="120"
              prop="receivableMoney"
            >
              <template slot="header">
                <div class="header-container">
                  应收金额
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="应收款金额，不包含折扣优惠、抹零、积分抵现、优惠券等优惠扣减的金额，但包含会员卡储值消费的金额"
                  >
                    <i class="el-icon-question" style="font-size: 14px"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              label="实收金额"
              align="center"
              minWidth="120"
              prop="billFactMoney"
            >
              <template slot="header">
                <div class="header-container">
                  实收金额
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="实收金额，不包含折扣优惠、抹零、积分抵现、优惠券等优惠扣减的金额，且不包含会员卡储值消费的金额"
                  >
                    <i class="el-icon-question" style="font-size: 14px"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="营收金额"
              sortable
              minWidth="120"
              align="center"
              prop="revenueMoney"
            />
            <el-table-column
              label="客单数"
              sortable
              minWidth="120"
              align="center"
              prop="customerUnitQty"
            />

            <el-table-column
              label="客单价"
              sortable
              align="center"
              prop="customerUnitPrice"
              minWidth="120"
            />
            <el-table-column
              label="退货笔数"
              sortable
              minWidth="120"
              align="center"
              prop="refundCount"
            />
            <el-table-column
              label="退货金额"
              sortable
              align="center"
              prop="refundMoney"
              min-width="120"
            />
            <el-table-column
              label="优惠总额"
              sortable
              align="center"
              prop="agioMoneySum"
              width="100"
            />
            <el-table-column
              label="赠送金额"
              sortable
              align="center"
              prop="largessMoney"
              width="100"
            />
            <el-table-column label="收款方式" align="center">
              <el-table-column
                :label="item.value"
                :prop="`${item.value}.${item.payMode}`"
                v-for="(item, index) in payModeList"
                :key="index"
                align="center"
                sortable
                width="120"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.payDetailList">
                    {{ payMoneyCompute(item.payMode, scope.row.payDetailList) }}
                  </span>
                  <span v-else>0</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :is-select-all="false"
            :total-com="total"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  listPayAnalysisAPI,
  listPayAnalysisSummaryAPI
} from '@/api/shop/analyse/posSaleDaily' // 门店收款分析明细
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import { fcount, isObject, isNumber } from '@/utils'
export default {
  name: 'posSaleDaily',
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectLocal,
    SelectRemote
  },
  data () {
    return {
      summary: [],
      summaryDateils: {},
      payModeList: [], //付款方式列表
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'shopNos', name: '门店编号' },
        { parameter: 'shopNames', name: '门店名称' }
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
      payTypeList: [
        {
          label: '充值',
          value: 1
        },
        {
          label: '销售',
          value: 2
        },
        {
          label: '预订',
          value: 3
        }
      ]
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 74
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        '/api/system/shop/report/pay/daily/listPayCashierExport',
        {
          ...this.queryParams
        },
        `门店收银日报导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //计算属性
    payMoneyCompute (payModeId, arr) {
      if (arr) {
        const findIndex = arr.findIndex(item => item.payModeId == payModeId)
        if (findIndex != -1) {
          return arr[findIndex].payMoney
        } else {
          return 0
        }
      }
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const res = await listPayAnalysisAPI(this.queryParams)
      if (res?.rows?.length > 0 && res?.rows[0]?.payTypes?.length > 0) {
        this.payModeList = res.rows[0].payTypes
      }
      const res1 = await listPayAnalysisSummaryAPI(this.queryParams)
      this.tableData = res.rows
      this.total = res.total
      this.summaryDateils = res1.data || {}
      this.loadingTable = false
    },
    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    // 跳转详情
    handleDetail (row) {
      const objName = {
        102: 'PurchaseInstoreDetail',
        103: 'PurchaseBackDetail'
      }
      this.$router.push({
        name: objName[row.billType],
        query: {
          billId: row.billId,
          type: 'Update'
        }
      })
    },
    //计算
    getSummaries ({ columns, data } = {}) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const sumItem = this.summary?.find(x =>
          isObject(x) ? x.key === column.property : x === column.property
        )
        if (sumItem || this.summaryDateils != '{}') {
          if (this.summaryDateils) {
            const arr = column?.property?.split('.')
            if (arr.length == 1) {
              sums[index] = this.summaryDateils[column.property] || ''
            } else if (arr.length > 1) {
              let sumsObj = this.summaryDateils?.payDetailList?.find(
                v => v.payModeId == arr[1]
              )
              sums[index] = sumsObj ? sumsObj.payMoney : ''
            }
          } else {
            const arr = column.property.split('.')
            let values = data.map(x => Number(x[column.property] || 0))
            if (arr?.length === 4) {
              const [p, i, key, s] = arr
              values = data.map(
                x => x[p]?.find?.(x => String(x[key]) === i)?.[s] || 0
              )
            }
            sums[index] = `${values.reduce(
              (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], '+'),
              0
            )}${isObject(sumItem) ? ` ${sumItem.unit}` : ''}`
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>
<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 94px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
