<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="门店编号、门店名称、商品编号、商品名称、条码、销售小票号"
            @isShowHighCom="getIsShowHigh"
          />
          <div v-show="showHigh">
            <div class="marT10 marB10 x-f">
              <span class="fS14MR10">商品</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.goodsIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listGoods',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择商品',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'goods' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10">商品分类</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.categoryIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listCategory',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择商品类别',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'category' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10">收银机</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.machineIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listMachine',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择收银机',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'machine' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10">收银员</span>
              <SelectRemote
                :multiple="true"
                v-model="queryParams.cashierIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listCashier',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择收银员',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'cashier' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
            </div>
            <div class="x-f">
              <span class="fS14MR10">门店</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.shopIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择门店',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'shop' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10" v-if="activeName == 'shop'">业务类型</span>
              <SelectLocal
                v-if="activeName == 'shop'"
                v-model="queryParams.lineTypes"
                :multiple="true"
                style="width: 250px"
                :option="{
                  data: lineTypeData,
                  value: 'value',
                  label: 'label'
                }"
              />
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom card-title="门店销售分析" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="门店销售分析明细" name="shop">
              <el-table
                ref="multipleTable1"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  prop="userId"
                  type="index"
                  width="80"
                />
                <el-table-column
                  label="销售日期"
                  align="center"
                  prop="billDateStr"
                  width="160"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="120"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />

                <el-table-column
                  label="收银机编码"
                  align="center"
                  prop="cashierNo"
                  width="120"
                />
                <el-table-column
                  label="收银机名称"
                  align="center"
                  prop="cashierName"
                  width="160"
                />
                <el-table-column
                  label="收银员编码"
                  align="center"
                  prop="cashierNo"
                  width="120"
                />
                <el-table-column
                  label="收银员"
                  align="center"
                  prop="cashierName"
                  width="160"
                />

                <el-table-column
                  label="小票号"
                  align="center"
                  prop="billNo"
                  width="180"
                />
                <el-table-column
                  label="类别编码"
                  align="center"
                  prop="categoryNo"
                  width="120"
                />
                <el-table-column
                  label="商品类别"
                  align="center"
                  prop="categoryName"
                  width="120"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  min-width="120"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  min-width="120"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="120"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="原价"
                  sortable
                  align="center"
                  prop="unitOldPrice"
                  width="150"
                />
                <el-table-column
                  label="售价"
                  sortable
                  align="center"
                  prop="unitPrice"
                  width="150"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  width="150"
                />
                <el-table-column
                  label="价格类型"
                  align="center"
                  prop="agioTypeName"
                  width="150"
                />
                <!-- <el-table-column
                  label="折扣%"
                  sortable
                  align="center"
                  prop="agioRate"
                  width="150"
                /> -->
                <el-table-column
                  label="折扣金额"
                  sortable
                  align="center"
                  prop="agioMoney1"
                  width="150"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                  width="150"
                />
                <el-table-column
                  label="折上折类型"
                  align="center"
                  prop="agioType2Name"
                  width="150"
                />
                <el-table-column
                  label="折上折比率%"
                  sortable
                  align="center"
                  prop="agioRate2"
                  width="150"
                />
                <el-table-column
                  label="折上折金额"
                  sortable
                  align="center"
                  prop="agioMoney2"
                  width="150"
                />
                <el-table-column
                  label="总优惠金额"
                  sortable
                  align="center"
                  prop="agioMoneySum"
                  width="150"
                />
                <el-table-column
                  label="舍入金额"
                  sortable
                  align="center"
                  prop="roundMoney"
                  width="150"
                />
                <el-table-column
                  label="业务类型"
                  align="center"
                  prop="lineTypeName"
                  width="110"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="商品销售分析" name="goods">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="商品类别"
                  align="center"
                  prop="categoryName"
                  width="160"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  min-width="160"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  min-width="160"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="160"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="120"
                />
                <el-table-column
                  label="售卖单位"
                  align="center"
                  prop="unitName"
                  width="120"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  width="150"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                  width="150"
                />
                <el-table-column
                  label="总优惠金额"
                  sortable
                  align="center"
                  prop="agioMoneySum"
                  width="150"
                />
                <el-table-column
                  label="单品客单数"
                  sortable
                  align="center"
                  prop="customerOrdersQty"
                  width="150"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店销售分析(分店+日期)" name="shopDate">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="销售日期"
                  align="center"
                  prop="billDateStr"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                />

                <el-table-column
                  label="优惠金额"
                  sortable
                  align="center"
                  prop="agioMoneySum"
                  width="120"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店销售分析(收银员)" name="cashier">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="销售日期"
                  align="center"
                  prop="billDateStr"
                />

                <el-table-column
                  label="收银员"
                  align="center"
                  prop="cashierName"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                />

                <el-table-column
                  label="优惠金额"
                  sortable
                  align="center"
                  prop="agioMoneySum"
                  width="120"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="日期汇总查询" name="date">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  prop="userId"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="销售日期"
                  align="center"
                  prop="billDateStr"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="类别汇总查询" name="category">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="类别编号"
                  align="center"
                  prop="categoryNo"
                  min-width="160"
                />
                <el-table-column
                  label="商品类别"
                  align="center"
                  prop="categoryName"
                  min-width="160"
                />
                <el-table-column
                  label="销售数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  width="160"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                  width="150"
                />
                <el-table-column
                  label="赠送数量"
                  sortable
                  align="center"
                  prop="unitQtyGift"
                  width="160"
                />
                <el-table-column
                  label="赠送金额"
                  sortable
                  align="center"
                  prop="unitMoneyGift"
                  width="150"
                />
                <el-table-column
                  label="优惠金额"
                  sortable
                  align="center"
                  prop="agioMoneySum"
                  width="120"
                />
                <el-table-column
                  label="退货数量"
                  sortable
                  align="center"
                  prop="unitQtyReturn"
                  width="160"
                />
                <el-table-column
                  label="退货金额"
                  sortable
                  align="center"
                  prop="unitMoneyReturn"
                  width="150"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="时间段汇总查询" name="time">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  prop="userId"
                  width="80"
                  type="index"
                />

                <el-table-column
                  label="时间段"
                  align="center"
                  prop="timeSlot"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                />

                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                />
                <el-table-column
                  label="总优惠金额"
                  sortable
                  align="center"
                  prop="agioMoneySum"
                />
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :is-select-all="false"
            :total-com="total"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  shopSaleAPI,
  shopSaleSummaryAPI,
  categoryAPI,
  categorySummaryAPI,
  saleCashierAPI,
  saleCashierSummaryAPI,
  saleShopDateAPI,
  saleShopDateSummaryAPI,
  listSaleAnalyseAPI,
  listSaleAnalyseSummaryAPI,
  dateAPI,
  dateSummaryAPI,
  timeSlotAPI,
  timeSlotSummaryAPI
} from '@/api/shop/analyse/posSale' // 门店销售分析明细
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import { fcount, isObject, isNumber } from '@/utils'
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
export default {
  name: 'PosSale',
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectRemote,
    SelectLocal
  },
  data () {
    return {
      activeName: 'shop',
      summary: [
        'unitQty',
        'agioMoney1',
        'saleMoney',
        'agioMoney2',
        'agioMoneySum',
        'roundMoney'
      ],
      summaryDateils: {},
      //业务类型
      lineTypeData: [
        {
          label: '销售退货商品',
          value: -1
        },
        {
          label: '正常业务',
          value: 0
        },
        {
          label: '临时赠品',
          value: 1
        },
        {
          label: '固定组合商品',
          value: 2
        },
        {
          label: '动态组合商品',
          value: 3
        },
        {
          label: '买满赠送商品',
          value: 4
        },
        {
          label: '组合优惠商品',
          value: 5
        },
        {
          label: '优惠券销售',
          value: 6
        },
        {
          label: '优惠券领取',
          value: 7
        },
        {
          label: '优惠券赠送',
          value: 8
        },
        {
          label: '辅料加值商品',
          value: 9
        },
        {
          label: '预订取货(本店预订)',
          value: 10
        },
        {
          label: '预订取货(他店预订)',
          value: 11
        },
        {
          label: '积分兑换商品',
          value: 21
        }
      ],
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'shopNos', name: '门店编号' },
        { parameter: 'shopNames', name: '门店名称' },
        { parameter: 'goodsNos', name: '商品编号' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '条码' },
        { parameter: 'billNos', name: '销售小票号' }
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      exportType: {
        shop: '/api/system/shop/report/sale/analysis/listSaleAnalysisDetailExport',
        goods:
          '/api/system/shop/report/sale/analysis/listSaleAnalysisGoodsExport',
        shopDate:
          '/api/system/shop/report/sale/analysis/listSaleAnalysisShopDateExport',
        cashier:
          '/api/system/shop/report/sale/analysis/listSaleAnalysisCashierExport',
        date: '/api/system/shop/report/sale/analysis/listSaleAnalysisDateExport',
        category:
          '/api/system/shop/report/sale/analysis/listSaleAnalysisCategoryExport',
        time: '/api/system/shop/report/sale/analysis/listSaleAnalysisTimeSlotExport'
      }, //定义导出枚举
      exportNameType: {
        shop: '门店销售分析（门店销售分析明细）',
        goods: '门店销售分析（商品销售分析）',
        shopDate: '门店销售分析（门店销售分析（分店+日期））',
        cashier: '门店销售分析（门店销售分析（收银员））',
        date: '门店销售分析（日期汇总查询）',
        category: '门店销售分析（类别汇总查询）',
        time: '门店销售分析（时间段汇总查询）'
      }, //定义导出名称枚举
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.multipleTable1.doLayout()
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 130
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        this.exportType[this.activeName],
        {
          ...this.queryParams
        },
        `${this.exportNameType[this.activeName]}导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    // 分页
    handleClick (tab) {
      this.getList()
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      let res = {}
      let res1 = {}
      if (this.activeName == 'goods') {
        res = await shopSaleAPI(this.queryParams)
        res1 = await shopSaleSummaryAPI(this.queryParams)
      } else if (this.activeName == 'category') {
        res = await categoryAPI(this.queryParams)
        res1 = await categorySummaryAPI(this.queryParams)
      } else if (this.activeName == 'cashier') {
        res = await saleCashierAPI(this.queryParams)
        res1 = await saleCashierSummaryAPI(this.queryParams)
      } else if (this.activeName == 'shopDate') {
        res = await saleShopDateAPI(this.queryParams)
        res1 = await saleShopDateSummaryAPI(this.queryParams)
      } else if (this.activeName == 'shop') {
        res = await listSaleAnalyseAPI(this.queryParams)
        res1 = await listSaleAnalyseSummaryAPI(this.queryParams)
      } else if (this.activeName == 'date') {
        res = await dateAPI(this.queryParams)
        res1 = await dateSummaryAPI(this.queryParams)
      } else if (this.activeName == 'time') {
        res = await timeSlotAPI(this.queryParams)
        res1 = await timeSlotSummaryAPI(this.queryParams)
      }
      this.tableData = res.rows
      this.total = res.total
      this.summaryDateils = res1.data || {}
      this.loadingTable = false
    },

    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    // 跳转详情
    handleDetail (row) {
      const billId = row.billId
      this.$router.push({
        name: 'apDetail',
        query: {
          billId: billId,
          type: 'Update'
        }
      })
    },
    getSummaries ({ columns, data } = {}) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const sumItem = this.summary?.find(x =>
          isObject(x) ? x.key === column.property : x === column.property
        )
        if (sumItem || this.summaryDateils != '{}') {
          if (this.summaryDateils) {
            const arr = column?.property?.split('.')
            if (arr.length == 1) {
              sums[index] = this.summaryDateils[column.property] || ''
            }
            // else if (arr.length) {
            //   if (
            //     arr.length == 3 &&
            //     arr[2] != 'unitQtyRate' &&
            //     arr[2] != 'unitMoneyRate'
            //   ) {
            //     let sumsObj = this.summaryDateils?.goodsShops?.find(
            //       v => v.shopId == arr[1]
            //     )
            //     sums[index] = sumsObj ? sumsObj[arr[2]] : ''
            //   }
            // }
          } else {
            const arr = column.property.split('.')
            let values = data.map(x => Number(x[column.property] || 0))
            if (arr?.length === 4) {
              const [p, i, key, s] = arr
              values = data.map(
                x => x[p]?.find?.(x => String(x[key]) === i)?.[s] || 0
              )
            }
            sums[index] = `${values.reduce(
              (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], '+'),
              0
            )}${isObject(sumItem) ? ` ${sumItem.unit}` : ''}`
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>
<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 94px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
