<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import { listPayAnalysisAPI } from "@/api/shop/analyse/posSaleDaily"; // 门店收款分析明细
export default {
  name: "PosSaleDaily",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "门店收银日报",
        listNo: true, // 序号
        // 新增自定义合计计算
        // customTotal: {
        //   apiHost: listPayAnalysisAPI,
        //   list: [
        //     {
        //       title: '营业实收',
        //       key: 'revenueMoney',
        //       value: '0.00'
        //     },
        //   ]
        // },
        //搜索
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        getListApi: listPayAnalysisAPI,
        // exportOption: {
        //   fastExportUrl:
        //     '/api/system/shop/report/pay/analysis/paymentDetailsListExport',
        //   exportName: '门店收银日报明细分析'
        // },
        //表格
        columns: [
          {
            prop: "payType",
            label: "支付类型",
            minWidth: 105,
            align: "center",
          },
          {
            prop: "remark",
            label: "概况",
            minWidth: 520,
            align: "center",
          },
          {
            prop: "payTypse",
            align: "center",
            label: "收款方式",
            children: () =>
              this.options.list?.[0]?.payTypes?.map?.((x) => ({
                ...x,
                label: x.payModeName,
                prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                minWidth: 110,
                summary: true,
                getValue: (row, prop) =>
                  row.payDetailList?.find((y) => y.payModeId === x.payModeId)
                    ?.payMoney || 0,
              })) || [],
          },
          {
            prop: "payMoney",
            label: "合计",
            minWidth: 120,
            align: "center",
          },
        ],
        summary: ["payMoney", "billFactMoney"],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          if (row?.payTypeId != 1 && row?.payTypeId != 4) {
            this.$router.push({
              name: "saleTotalDetail",
              query: {
                billNo: row.billNo,
                type: "Update",
              },
            });
          } else {
            this.$router.push({
              name: "saleTotalDetail",
              query: {
                billId: row.billId,
                type: "Update",
              },
            });
          }

          break;
        default:
          break;
      }
    },
  },
};
</script>
