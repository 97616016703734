var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                    "is-search-input-title":
                      "门店编号、门店名称、商品编号、商品名称、条码、退货小票号",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "marT10 marB10 x-f" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("收银员"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listCashier",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择收银员",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({
                                          key: "cashier",
                                        }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.cashierIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "cashierIds", $$v)
                            },
                            expression: "queryParams.cashierIds",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("商品"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listGoods",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择商品",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({
                                          key: "goods",
                                        }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.goodsIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "goodsIds", $$v)
                            },
                            expression: "queryParams.goodsIds",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("门店"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listShop",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择门店",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({ key: "shop" }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.shopIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "shopIds", $$v)
                            },
                            expression: "queryParams.shopIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "门店退货分析" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "门店退货明细查询", name: "back" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "日期",
                                align: "center",
                                prop: "billDateStr",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员编号",
                                align: "center",
                                prop: "cashierNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员名称",
                                align: "center",
                                prop: "cashierName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售小票号",
                                align: "center",
                                prop: "billNo",
                                width: "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "退货小票号",
                                align: "center",
                                prop: "backBillNo",
                                width: "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "售价",
                                sortable: "",
                                align: "center",
                                prop: "unitPrice",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "unitMoney",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoney",
                                width: "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "门店退货汇总查询（按商品）",
                          name: "goodsback",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoney",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "unitMoney",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "门店退货汇总查询（按门店）",
                          name: "shop",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoney",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "unitMoney",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "门店退货汇总查询（按类别）",
                          name: "category",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编码",
                                align: "center",
                                prop: "categoryNo",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别名称",
                                align: "center",
                                prop: "categoryName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoney",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "销售金额",
                                sortable: "",
                                align: "center",
                                prop: "unitMoney",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    "is-select-all": false,
                    "total-com": _vm.total,
                  },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }