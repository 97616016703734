<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="门店编号、门店名称、商品编号、商品名称、条码、退货小票号"
            @isShowHighCom="getIsShowHigh"
          />
          <div v-show="showHigh">
            <div class="marT10 marB10 x-f">
              <span class="fS14MR10">收银员</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.cashierIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listCashier',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择收银员',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'cashier' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10">商品</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.goodsIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listGoods',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择商品',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'goods' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10">门店</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.shopIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择门店',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'shop' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom card-title="门店退货分析" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="门店退货明细查询" name="back">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :summary-method="getSummaries"
                :max-height="tableHeight"
                :height="tableHeight"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="日期"
                  align="center"
                  prop="billDateStr"
                  width="150"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="150"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="150"
                />
                <el-table-column
                  label="收银员编号"
                  align="center"
                  prop="cashierNo"
                  width="150"
                />
                <el-table-column
                  label="收银员名称"
                  align="center"
                  prop="cashierName"
                  width="150"
                />
                <el-table-column
                  label="销售小票号"
                  align="center"
                  prop="billNo"
                  width="180"
                />
                <el-table-column
                  label="退货小票号"
                  align="center"
                  prop="backBillNo"
                  width="180"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="150"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="120"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="150"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="售价"
                  sortable
                  align="center"
                  prop="unitPrice"
                  width="150"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  width="150"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="unitMoney"
                  width="150"
                />
                <el-table-column
                  label="优惠金额"
                  sortable
                  align="center"
                  prop="agioMoney"
                  width="150"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店退货汇总查询（按商品）" name="goodsback">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :summary-method="getSummaries"
                :max-height="tableHeight"
                :height="tableHeight"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />

                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                />
                <el-table-column label="条码" align="center" prop="barcode" />
                <el-table-column label="规格" align="center" prop="goodsSpec" />
                <el-table-column label="单位" align="center" prop="unitName" />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                />
                <el-table-column
                  label="优惠金额"
                  sortable
                  align="center"
                  prop="agioMoney"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="unitMoney"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店退货汇总查询（按门店）" name="shop">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :summary-method="getSummaries"
                :max-height="tableHeight"
                :height="tableHeight"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />

                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                />
                <el-table-column
                  label="优惠金额"
                  sortable
                  align="center"
                  prop="agioMoney"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="unitMoney"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店退货汇总查询（按类别）" name="category">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :summary-method="getSummaries"
                :max-height="tableHeight"
                :height="tableHeight"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />

                <el-table-column
                  label="类别编码"
                  align="center"
                  prop="categoryNo"
                />
                <el-table-column
                  label="类别名称"
                  align="center"
                  prop="categoryName"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                />
                <el-table-column
                  label="优惠金额"
                  sortable
                  align="center"
                  prop="agioMoney"
                />
                <el-table-column
                  label="销售金额"
                  sortable
                  align="center"
                  prop="unitMoney"
                />
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :is-select-all="false"
            :total-com="total"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  listReturnAPI,
  listReturnSummaryAPI,
  listGoodsReturnAPI,
  listGoodsReturnSummaryAPI
} from '@/api/shop/analyse/posBack' // 门店退款
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import { fcount, isObject, isNumber } from '@/utils'
export default {
  name: 'PosBack',
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectRemote
  },
  data () {
    return {
      summary: [],
      summaryDateils: {},
      activeName: 'back',
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'shopNos', name: '门店编号' },
        { parameter: 'shopNames', name: '门店名称' },
        { parameter: 'goodsNos', name: '商品编号' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '条码' },
        { parameter: 'backBillNos', name: '退货小票号' }
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      exportType: {
        back: '/api/system/shop/report/sale/analysis/listReturnAnalysisDetailExport',
        goodsback:
          '/api/system/shop/report/sale/analysis/listGoodsReturnQueryExport'
      }, //定义导出枚举
      exportNameType: {
        back: '门店退货分析（门店退货明细查询）',
        goodsback: '门店退货分析（门店商品退货查询）'
      }, //定义导出名称枚举
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 130
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        this.exportType[this.activeName],
        {
          ...this.queryParams
        },
        `${this.exportNameType[this.activeName]}导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    getSummaries ({ columns, data } = {}) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const sumItem = this.summary?.find(x =>
          isObject(x) ? x.key === column.property : x === column.property
        )
        if (sumItem || this.summaryDateils != '{}') {
          if (this.summaryDateils) {
            const arr = column?.property?.split('.')
            if (arr.length == 1) {
              sums[index] = this.summaryDateils[column.property] || ''
            }
          } else {
            const arr = column.property.split('.')
            let values = data.map(x => Number(x[column.property] || 0))
            if (arr?.length === 4) {
              const [p, i, key, s] = arr
              values = data.map(
                x => x[p]?.find?.(x => String(x[key]) === i)?.[s] || 0
              )
            }
            sums[index] = `${values.reduce(
              (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], '+'),
              0
            )}${isObject(sumItem) ? ` ${sumItem.unit}` : ''}`
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 分页
    handleClick (tab) {
      this.getList()
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      let res = {}
      let res1 = {}
      if (this.activeName == 'back') {
        res = await listReturnAPI(this.queryParams)
        res1 = await listReturnSummaryAPI(this.queryParams)
      } else if (this.activeName == 'goodsback') {
        res = await listGoodsReturnAPI(this.queryParams)
        res1 = await listGoodsReturnSummaryAPI(this.queryParams)
      }

      this.tableData = res.rows
      this.total = res.total
      this.summaryDateils = res1.data || {}
      this.loadingTable = false
    },

    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 94px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
